@import url("https://use.typekit.net/xmf5hhl.css");

/* Global Reset */
*, *:before, *:after {
  box-sizing: inherit;
}

body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
ul, dl, dd,
figure,
hr,
fieldset, legend {
  margin:  0;
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/* End Global Reset */

html,
body,
#root {
  height: 100%;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: proxima-nova, 'Roboto', 'Helvetica Neue', sans-serif;
  color: #202450; /* to replace by theme color */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
